.successModal {
    .header {
        align-items: center;
        justify-content: center;
        background-color: #2A2A2A;

        .title {
            color: #fff;
            font-size: 1rem;
        }
    }

    .body {
        display: flex;
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;

        p {
            font-weight: 500;
            text-align: center;
        }

        .close {
            width: 7rem;
            border-radius: 8px;
            border-color: #FF0000;
            background-color: #FF0000;
        }

        .icon {
            width: 10rem;
            margin-bottom: 1rem;
        }

        .proofOfDisbursement {
            gap: 1rem;
            display: flex;
            align-items: flex-start;

            .image {
                width: 10rem;
                cursor: pointer;
            }
        }
    }
}