.header {
    flex: 1;
    gap: 2rem;
    width: 100%;
    max-width: 80%;
    padding: 2rem;
    margin: 0 auto;
    height: 333px;
    display: flex;
    position: relative;
    border-radius: 15px;
    flex-direction: column;
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('../../../assets/images/profile-bg.png');

    .innerHeader {
        width: 94%;
        padding: 10px 2rem;
        position: absolute;
        bottom: -15%;
        border-radius: 15px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        display: flex;
        gap: 10px;
        left: 3%;
        align-items: center;
        justify-content: space-evenly;

        .profile {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (max-width: 934px) {
                flex-direction: column;
                align-items: flex-start;
            }

            p {
                margin: 0;
            }

            span {
                margin: 0;
            }

            .imageContainer {
                width: 10rem;

                .avatar {
                    width: 8rem;
                    height: 8rem;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }

            .name {
                p { 
                    width: 100%;
                    position: relative;
                    color: #1A1A1A;
                    font-weight: 600;
                    font-size: 1.2rem;

                    .check {
                        width: 14px;
                        position: absolute;
                        right: 0;
                    }
                }

                span {
                    font-size: 1rem;
                    color: #1A1A1A;
                    font-weight: 500;
                }
            }
        }

        .details {
            width: 100%;
            display: grid;
            gap: 10px;
            grid-template-columns: auto auto auto;

            @media only screen and (max-width: 1112px) {
                grid-template-columns: auto auto;
            }

            @media only screen and (max-width: 976px) {
                grid-template-columns: auto;
            }

            p {
                font-weight: 600;
                font-size: 12px;
                display: flex;
                flex-direction: column;

                span {
                    font-weight: 500;
                }

                b {
                    width: 100%;
                }
            }
        }
    }
}