.header {
    width: 80%;
    margin: 0 auto 2.5rem;
    height: 333px;
    border-radius: 15px;
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('../../../assets/images/profile-bg.png');
    position: relative;

    .innerHeader {
        width: 94%;
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        border-radius: 15px;
        padding: 10px 2rem;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        gap: 10px;
        position: absolute;
        bottom: -15%;
        left: 3%;

        @media only screen and (max-width: 991px) {
            grid-template-columns: auto auto;
        }

        @media only screen and (max-width: 767px) {
            top: 15%;
            grid-template-columns: auto;
        }

        .profile {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 1vw;

            @media only screen and (max-width: 991px) {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            @media only screen and (max-width: 767px) {
                grid-column-start: 1;
                grid-column-end: 2;
            }

            p {
                margin: 0;
            }

            span {
                margin: 0;
            }

            .imageContainer {
                width: 8rem;
                height: 8rem;

                .avatar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }

            .name {
                p {
                    width: 100%;
                    position: relative;
                    color: #1A1A1A;
                    font-weight: 600;
                    font-size: 1.2rem;

                    .check {
                        width: 14px;
                        position: absolute;
                        right: 0;
                    }
                }

                span {
                    font-size: 1rem;
                    color: #1A1A1A;
                    font-weight: 500;
                }
            }
        }

        .details {
            width: 100%;
            gap: 10px;
            display: grid;
            grid-template-columns: auto auto auto;

            @media only screen and (max-width: 1164px) {
                grid-template-columns: auto auto;
            }

            @media only screen and (max-width: 1094px) {
                grid-template-columns: auto;
            }

            @media only screen and (max-width: 991px) {
                grid-template-columns: auto auto;
            }

            @media only screen and (max-width: 767px) {
                grid-template-columns: auto auto auto;
            }

            p {
                font-size: 12px;
                display: flex;
                flex-direction: column;
    
                span {
                    font-weight: 500;
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media only screen and (max-width: 767px) {
                flex-direction: row;
            }

            button.customize {
                font-weight: 600;
                color: #D1002A;
                font-size: 0.7rem;
                border-color: #FFDFE6;
                background-color: #FFDFE6;
            }

            button.password {
                font-weight: 600;
                color: #02CB60;
                font-size: 0.7rem;
                border-color: #EDF9F4;
                background-color: #EDF9F4;
            }

            button.tc {
                font-weight: 600;
                color: #559EFE;
                font-size: 0.7rem;
                border-color: #D6E8FF;
                background-color: #D6E8FF;
            }
        }
    }
}