.successModal {
    .header {
        align-items: center;
        justify-content: center;
        background-color: #2A2A2A;

        .title {
            color: #fff;
            font-size: 1rem;
        }
    }

    .body {
        display: flex;
        padding: 2rem;
        align-items: center;
        flex-direction: column;

        p {
            font-weight: 500;
            text-align: center;
        }

        .buttons {
            gap: 1rem;
            width: 100%;
            display: flex;
            margin: 1rem 0 0;
            align-items: center;
            justify-content: center;

            .confirm {
                width: 7rem;
                border-radius: 8px;
                border-color: #FF0000;
                background-color: #FF0000;
            }

            .close {
                width: 7rem;
                border-radius: 8px;
                border-color: #BCBCC5;
                background-color: #BCBCC5;
            }
        }

        .icon {
            width: 10rem;
        }
    }
}