.dashboard {
    gap: 2rem;
    display: flex;
    align-items: center;
    padding-right: 10px;
    flex-direction: column;

    .approvedContent {
        flex: 1;
        gap: 2rem;
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 2rem;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                flex: 1;
                margin: 0;
                font-size: 1rem;
                font-weight: 600;
            }

            .goBack {
                gap: 10px;
                margin: 0;
                padding: 0;
                border: none;
                display: flex;
                font-weight: 600;
                color: #1A1A1A;
                align-items: center;
                background-color: #fff;

                svg {
                    height: 1rem;
                }
            }
        }

        .content {
            .uploadContainer {
                gap: 2rem;
                display: flex;
                flex-direction: column;
            }

            .detailsContainer {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                h1 {
                    margin: 0;
                    font-weight: 600;
                    color: #D1002A;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                }

                textarea.description {
                    margin: 0;
                    padding: 0;
                    border: none;
                    cursor: default;

                    &:focus,
                    &:active {
                        box-shadow: none;
                    }
                }

                .details {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        display: flex;
                        font-weight: 600;
                        text-align: center;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 1rem;

                        span {
                            font-weight: 400;

                            img {
                                width: 47px;
                                height: 47px;
                                object-fit: cover;
                                border-radius: 8px;
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .castDetails {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    p {
                        gap: 1rem;
                        margin: 0;
                        font-weight: 600;
                    }

                    ul,
                    li {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                    }

                    .artistOption {
                        gap: 7px;
                        display: flex;
                        align-items: center;


                        img {
                            width: 2rem;
                            height: 2rem;
                            object-fit: cover;
                        }

                        p {
                            margin: 0;
                            font-weight: 500;
                            font-size: 1rem;

                            span {
                                display: block;
                                font-weight: 400;
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
        }

        .loading {
            height: calc(100vh - 16.5rem);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 5rem;
                height: 5rem;
            }
        }
    }
}