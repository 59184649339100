.channelContainer {
    height: 100%;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h4 {
        font-weight: 500;
        font-size: 1.2rem;
        margin: 0 0 1rem;
    }

    .mainContent {
        gap: 1rem;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        margin: 2rem auto 0;
        border-radius: 15px;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 3px 6px #00000029;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .userInfo {
                gap: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    width: 3rem;
                    height: 3rem;
                    object-fit: cover;
                    border-radius: 20px;
                }

                p {
                    margin: 0;
                    font-weight: 600;
                    line-height: 1.2;

                    span {
                        display: block;
                        font-weight: 400;
                    }
                }
            }
        }

        .details {
            gap: 2rem;
            padding: 0;
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;

            .description {
                display: block;
                margin: 0;

                span {
                    display: block;
                    margin-top: 1rem;
                }
            }
        }
    }

    .actionButtons {
        gap: 1rem;
        width: 100%;
        display: flex;
        margin: 2rem 0 0;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 7rem;
            font-size: 0.8rem;
            font-weight: 500;

            &.save {
                color: #02CB60;
                border-color: #EDF9F4;
                background-color: #EDF9F4;
            }

            &.cancel {
                color: #D1002A;
                border-color: #FFDFE6;
                background-color: #FFDFE6;
            }
        }
    }

    .formContainer {
        width: 100%;

        .loading {
            width: 100%;
            display: flex;
            padding: 1rem 0;
            align-items: center;
            justify-content: center;

            svg {
                width: 5rem;
            }
        }
    }
}