.file-upload {
    gap: 1rem;
    width: 100%;
    display: flex;
    max-width: 31rem;
    flex-direction: column;
    align-items: flex-start;

    .upload-file-preview {
        margin: 0;
        color: #fff;
        height: 38px;
        display: flex;
        padding: 0 5px;
        flex: 0 0 12rem;
        cursor: pointer;
        font-weight: 500;
        border-radius: 3px;
        position: relative;
        align-items: center;
        background-color: #D0D0D0;
        border: 4px dashed #848484;
    }

    .hidden-file {
        display: none;
    }

    .language-selection {
        flex: 0 0 12rem;
    }

    .add-button {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        fill: #02CB60;
    }

    .remove-button {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        fill: #D1002A;
    }

    .subtitleDetails {
        gap: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
    }
}