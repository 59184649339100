.header {
    flex: 1;
    gap: 2rem;
    width: 100%;
    max-width: 80%;
    padding: 2rem;
    margin: 0 auto;
    height: 333px;
    display: flex;
    position: relative;
    border-radius: 15px;
    flex-direction: column;
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('../../../assets/images/profile-bg.png');

    .innerHeader {
        gap: 1rem;
        width: 100%;
        display: flex;
        bottom: -12rem;
        position: relative;
        border-radius: 15px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .selectionContainer {
            gap: 2rem;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            border-right: solid 2px #C7C7C7;

            .imageUpload {
                width: 8rem;
                height: 8rem;
                display: flex;
                margin-top: 1rem;
                position: relative;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .overlay {
                    gap: 5px;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: none;
                    cursor: pointer;
                    position: absolute;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    background-color: rgba(116, 117, 117, 0.5);

                    p {
                        width: 7rem;
                        color: #fff;
                        font-size: 0.7rem;
                        text-align: center;
                    }

                    svg {
                        width: 2rem;
                    }

                    ;
                }

                .hiddenProfile {
                    display: none;
                }

                &:hover,
                &:active,
                &:focus {
                    .overlay {
                        display: flex;
                    }
                }
            }

            .selection {
                width: 100%;
                margin-bottom: 1rem;
                border-top: solid 2px #F5F5F5;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        margin: 0;
                        padding: 0;
                        border-bottom: solid 2px #F5F5F5;

                        button {
                            gap: 1rem;
                            width: 100%;
                            border: none;
                            display: flex;
                            font-weight: 500;
                            color: #1A1A1A;
                            border-radius: 0;
                            font-size: 0.8rem;
                            align-items: center;
                            padding: 6px 1.5rem;
                            background-color: transparent;

                            svg {
                                width: 1rem;
                            }

                            &.active {
                                color: #D1002A;

                                svg {
                                    fill: #D1002A;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &:hover,
    &:active,
    &:focus {
        .bannerOverlay {
            display: flex;
        }
    }

    .hiddenBanner {
        display: none;
    }

    .bannerOverlay {
        gap: 5px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        cursor: pointer;
        position: absolute;
        border-radius: 15px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(116, 117, 117, 0.5);

        p {
            width: 7rem;
            color: #fff;
            font-size: 0.7rem;
            text-align: center;
        }

        svg {
            width: 2rem;
        }

        ;
    }
}