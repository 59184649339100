.content {
    gap: 2rem;
    width: 100%;
    margin: auto;
    max-width: 80%;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    .historyContainer {
        width: 100%;
        padding: 1rem;
        margin: 0 auto 2rem;
        border-radius: 15px;
        box-shadow: 0px 3px 6px #00000029;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
                font-weight: 600;
                font-size: 0.9rem;
                text-transform: uppercase;
            }

            svg {
                width: 1rem;
                cursor: pointer;
            }
        }

        .transactionList {
            margin-top: 1rem;

            ul {
                margin: 0;
                padding: 0;

                li.list {
                    margin: 0;
                    display: flex;
                    gap: 1rem;
                    padding: 10px 0;
                    align-items: center;
                    justify-content: flex-start;
                    border-bottom: solid 2px rgb(253, 250, 249);

                    .leftContainer {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        img {
                            width: 3.5rem;
                            height: 2rem;
                            border-radius: 8px;
                            object-fit: cover;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 600;
                            font-size: 0.8rem;

                            span {
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }

                    .rightContainer {
                        p {
                            margin: 0;
                            padding: 0;
                            font-weight: 600;
                            font-size: 0.8rem;
                            text-align: left;

                            span {
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }

    .filterContainer {
        width: 100%;
        padding: 1rem;
        display: flex;
        margin: 0 auto;
        border-radius: 15px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-shadow: 0px 3px 6px #00000029;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        p {
            flex: 1;
            margin: 0;
            font-weight: 600;
            font-size: 0.9rem;
            text-transform: uppercase;
        }

        svg {
            width: 1rem;
            cursor: pointer;
        }

        select {
            flex: 0 0 10rem;
        }

        .faqList {
            width: 100%;
            margin: 2rem 0 0;

            .accordion {
                border: none;
                margin: 0 0 1rem;
                border-radius: 15px !important;
                box-shadow: 0px 3px 6px #00000029;

                h2 {
                    button {
                        font-size: 1rem;
                        color: #2A2A2A;
                        font-weight: bold;
                        text-transform: capitalize;
                    }
                }

                div {
                    p {
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .mainContent {
        gap: 1rem;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        margin: 2rem auto 0;
        border-radius: 15px;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 3px 6px #00000029;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .userInfo {
                gap: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    width: 3rem;
                    height: 3rem;
                    object-fit: cover;
                    border-radius: 10px;
                }

                p {
                    margin: 0;
                    font-weight: 600;
                    line-height: 1.2;

                    span {
                        display: block;
                        font-weight: 400;
                    }
                }
            }

            button.button {
                font-weight: 600;
                font-size: 0.8rem;
                text-transform: capitalize;

                &.pending {
                    color: #02CB60;
                    border-color: #EDF9F4;
                    background-color: #EDF9F4;
                }

                &.approved {
                    color: #02CB60;
                    border-color: #EDF9F4;
                    background-color: #EDF9F4;
                }

                &.declined {
                    color: #D1002A;
                    border-color: #FFDFE6;
                    background-color: #FFDFE6;
                }
            }
        }

        .details {
            gap: 2rem;
            padding: 0;
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;

            .description {
                display: block;
                margin: 0;

                span {
                    display: block;
                    margin-top: 1rem;
                }
            }

            // .videoContainer {
            //     display: block;
            //     cursor: pointer;
            //     border-radius: 3px;
            //     position: relative;
            //     padding-top: 56.25%;

            //     video {
            //         top: 0;
            //         left: 0;
            //         right: 0;
            //         bottom: 0;
            //         gap: 10px;
            //         margin: auto;
            //         color: #fff;
            //         display: flex;
            //         font-weight: 500;
            //         position: absolute;
            //         align-items: center;
            //         justify-content: center;
            //         flex-direction: column;
            //     }
            // }
        }
    }

    .loading {
        padding: 2rem 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg {
            width: 3rem;
            height: 3rem;
        }
    }
}