.castContainer {
    flex: 1 1;
    gap: 2rem;
    width: 100%;
    display: flex;
    padding: 2rem;
    border-radius: 10px;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);

    h4 {
        margin: 0;
        font-size: 1rem;
    }

    .artistOption {
        gap: 7px;
        display: flex;
        align-items: center;

        img {
            width: 2rem;
            height: 2rem;
            object-fit: cover;
        }

        p {
            margin: 0;
            font-weight: 500;
            font-size: 1rem;

            span {
                display: block;
                font-weight: 400;
                font-size: 0.7rem;
            }
        }
    }

    .newCastDetails {
        .castSingleList {
            gap: 1rem;
            display: flex;
            align-items: center;
            margin: 0 0 1rem;
            padding: 0 0 1rem;
            border-bottom: 1px solid #ced4da !important;

            .details {
                gap: 5px;
                display: flex;
                flex-direction: column;

                .formControl {
                    background-color: #eee !important;
                    border: 1px solid #ced4da !important;
                }
            }
        }
    }
}