.navigation-bar {
  .right-container {
    nav {
      .user-account {
        width: 30px;
        height: 30px;

        button {
          width: 30px;
          height: 30px;
        }
      }

      .navbar-brand {
        gap: 1rem;
        display: flex;
        align-items: center;
      }
    }

    nav.navbar {
      background-color: #1A1A1A;

      .container-fluid {
        .navbar-brand {
          flex: 1;

          img {
            width: 7rem;
          }

          span.welcome {
            font-size: 14px;
          }

          .navbar-toggler {
            border: none;

            &:focus,
            &:active {
              border: none;
              box-shadow: none;
            }
          }

          .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
          }
        }

        .center-date {
          flex: 2;
          color: #fff;
          text-align: center;
        }

        .user-account {
          flex: 1;
          display: flex;
          align-items: center;

          img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 8px;
          }

          p {
            margin: 0;
            padding: 0 1rem;
            color: #fff;
          }

          .logout {
            cursor: pointer;
          }
        }
      }
    }

    .main-container {
      padding: 2rem;
      background-color: #eeeeee;
      min-height: calc(100vh - 78.53px);
    }
  }
}