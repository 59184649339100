// GLOBAL SCSS.
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap');

$mainColor: #168a3e;
$lightMainColor: #97e09b;

html * {
  font-family: 'Ubuntu', sans-serif;
}

input.form-control {
  &:focus {
    border-color: $mainColor;
    box-shadow: 0 0 0 0.25rem $lightMainColor;
  }
}

.global-form-input {
  position: relative;

  input {
    border: none;
    border-radius: 0;
    border-bottom: solid 2px #575757;
    background-color: #fff !important;

    &:-internal-autofill-selected {
      background-color: #fff !important;
    }

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #9A1C21;
    }
  }

  .eye-icon {
    top: 0;
    bottom: 0;
    width: 20px;
    right: 10px;
    height: 15px;
    margin: auto;
    cursor: pointer;
    position: absolute;

    &.hide {
      right: 8px;
      width: 25px;
      height: 19px;
    }
  }
}

#sidebar-container {
  .offcanvas-header {
    .offcanvas-title {
      font-size: 1rem;
    }
  }

  .offcanvas-body {
    .nav-link {
      gap: 5px;
      display: flex;
      font-size: 12px;
      color: #848484;
      line-height: 1.2;
      font-weight: 500;
      text-align: center;
      align-items: center;
      flex-direction: column;

      svg {
        width: 2rem;
        fill: #848484;
      }

      &.active {
        color: #D1002A;

        svg {
          fill: #D1002A;
        }
      }
    }
  }
}

.offcanvas.offcanvas-start {
  width: 8rem !important;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  width: 8rem !important;
}

button.btn.view-data {
  border: none;
  font-weight: 500;
  font-size: 0.8rem;
  color: #2A2A2A;
  background-color: #fff;

  &.button-disabled {
    cursor: not-allowed;
  }
}

input.form-control,
select.form-select,
textarea {
  border: none;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

span.badge {
  padding: 10px;
  font-size: 1rem;

  &.pending {
    color: #DAD044;
    background-color: #FFF9AF;
  }

  &.declined {
    color: #D1002A;
    border-color: #FFDFE6;
    background-color: #FFDFE6;
  }

  &.approved {
    color: #1FD172;
    background-color: #EDF9F4;
  }
}

#TableAction {
  .pop-up-cont {
    .column-direction {
      display: flex;
      flex-direction: column;

      button {}
    }
  }
}

.container-fluid {
  margin: auto;
  max-width: 1440px;
}