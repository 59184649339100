.dashboard {
    gap: 2rem;
    display: flex;
    align-items: center;
    padding-right: 10px;
    flex-direction: column;

    .uploadContent {
        flex: 1;
        gap: 2rem;
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 2rem;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                flex: 1;
                margin: 0;
                font-size: 1rem;
                font-weight: 500;
            }

            .filters {
                flex: 1;
                gap: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button.addContent {
                    gap: 10px;
                    height: 36px;
                    display: flex;
                    flex: 0 0 10rem;
                    border-color: #D1002A;
                    background-color: #D1002A;

                    img {
                        width: 20px;
                    }
                }

                .search {
                    flex: 0 0 15rem;
                    position: relative;

                    .searchIcon {
                        top: 0;
                        bottom: 0;
                        left: 10px;
                        margin: auto;
                        width: 1.2rem;
                        position: absolute;
                    }

                    input {
                        padding-left: 2.3rem;
                        background-color: #F2F5FA;

                        &::placeholder {}
                    }
                }

                .filter {
                    flex: 0 0 15rem;
                    position: relative;

                    .filterIcon {
                        top: 0;
                        bottom: 0;
                        left: 10px;
                        margin: auto;
                        width: 1.2rem;
                        position: absolute;
                    }

                    select {
                        padding-left: 2.3rem;
                        background-color: #F2F5FA;

                        &::placeholder {}
                    }
                }
            }
        }

        .content {
            .uploadContainer {
                gap: 2rem;
                display: flex;
                flex-direction: column;

                .label {
                    margin: 0 0 10px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            .detailsContainer {
                gap: 2rem;
                display: flex;
                flex-direction: column;

                .decline {
                    font-weight: bold;
                    color: red;

                    span {
                        color: black;
                    }
                }

                .settings {
                    gap: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .actions {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    .groupFamily {
                        flex: 0 0 15rem;
                    }

                    .buttons {
                        gap: 10px;
                        display: flex;
                        align-items: center;

                        input {
                            width: 5rem;
                            display: block;

                            &:focus,
                            &:active {
                                box-shadow: none;
                            }
                        }

                        .submit {
                            font-weight: 500;
                            color: #02CB60;
                            border-color: #EDF9F4;
                            background-color: #EDF9F4;
                        }

                        .cancel {
                            font-weight: 500;
                            color: #D1002A;
                            border-color: #FFDFE6;
                            background-color: #FFDFE6;
                        }

                        .loading {
                            height: 3rem;
                            width: 3rem;
                        }
                    }
                }
            }
        }

        .loading {
            height: calc(100vh - 16.5rem);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 5rem;
                height: 5rem;
            }
        }
    }
}