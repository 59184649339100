.selfie-upload {
    width: 100%;

    .upload-selfie-preview {
        display: block;
        cursor: pointer;
        border-radius: 3px;
        position: relative;
        padding-top: 56.25%;
        background-color: #D0D0D0;
        border: 4px dashed #848484;

        .center-data {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            gap: 10px;
            margin: auto;
            color: #fff;
            display: flex;
            font-weight: 500;
            position: absolute;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }

    .selfie-preview {
        margin: 0;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-top: 56.25%;

        img {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
        }
    }

    input {
        display: none;
    }
}