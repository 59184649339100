.TCModal {
    .header {
        border: none;

        .title {
            gap: 1rem;
            width: 100%;
            display: flex;
            color: #4E4E4E;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            img {
                height: 6rem;
                width: auto;
            }
        }

        button {
            top: 1rem;
            right: 1rem;
            position: absolute;
            fill: #D1002A !important;

            &:focus,
            &:active {
                box-shadow: none;
            }
        }
    }

    .body {
        h2 {
            gap: 1rem;
            width: 100%;
            display: flex;
            color: #4E4E4E;
            font-size: 1.5rem;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        textarea {
            border: none;

            &:active,
            &:focus {
                box-shadow: none;
                max-height: 266px;
                overflow-y: auto;
            }
        }
    }
}