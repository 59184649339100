.dashboard {
    gap: 2rem;
    display: flex;
    align-items: center;
    padding-right: 10px;
    flex-direction: column;

    .recentUploads {
        flex: 1;
        gap: 2rem;
        width: 100%;
        padding: 2rem;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                flex: 1;
                margin: 0;
                font-size: 1rem;
                font-weight: 500;
            }

            .filters {
                flex: 1;
                gap: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button.addContent {
                    gap: 10px;
                    height: 36px;
                    display: flex;
                    flex: 0 0 10rem;
                    border-color: #D1002A;
                    background-color: #D1002A;

                    img {
                        width: 20px;
                    }
                }

                button.searchButton {
                    gap: 10px;
                    height: 36px;
                    display: flex;
                    flex: 0 0 7rem;
                    align-items: center;
                    border-color: #D1002A;
                    justify-content: center;
                    background-color: #D1002A;
                }

                .search {
                    flex: 0 0 15rem;
                    position: relative;

                    .searchIcon {
                        top: 0;
                        bottom: 0;
                        left: 10px;
                        margin: auto;
                        width: 1.2rem;
                        position: absolute;
                    }

                    input {
                        padding-left: 2.3rem;
                        background-color: #F2F5FA;

                        &::placeholder {}
                    }
                }

                .filter {
                    flex: 0 0 15rem;
                    position: relative;

                    .filterIcon {
                        top: 0;
                        bottom: 0;
                        left: 10px;
                        margin: auto;
                        width: 1.2rem;
                        position: absolute;
                    }

                    select {
                        padding-left: 2.3rem;
                        background-color: #F2F5FA;

                        &::placeholder {}
                    }
                }
            }
        }

        .UploadTable {
            thead {
                tr {
                    th {
                        font-weight: 500;
                        font-size: 0.9rem;
                    }
                }
            }

            tbody {
                td {
                    vertical-align: middle;

                    .thumnbnail {
                        width: 10rem;
                        height: 5rem;
                        object-fit: cover;
                    }

                    &.loading {
                        padding: 1rem 0;

                        svg {
                            width: 5rem;
                        }
                    }
                }
            }
        }
    }
}