.dashboard {
    gap: 2rem;
    display: flex;
    align-items: center;
    padding-right: 10px;
    flex-direction: column;

    .cardList {
        gap: 10px;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto auto;
        
        @media only screen and (max-width: 991px) {
            grid-template-columns: auto auto auto;
        }
        @media only screen and (max-width: 767px) {
            grid-template-columns: auto auto;
        }
        @media only screen and (max-width: 540px) {
            grid-template-columns: auto;
        }

        .card {
            flex: 1;
            gap: 1rem;
            display: flex;
            border-radius: 10px;
            padding: 1.5rem 1rem;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: #fff;
            box-shadow: 0px 3px 6px #00000029;

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    margin: 0;
                    font-weight: 500;

                    img {
                        margin-right: 10px;
                    }
                }

                .action {
                    cursor: pointer;
                }
            }

            .content {
                gap: 1rem;
                display: flex;
                flex-direction: column;

                h4 {
                    margin: 0;
                    font-weight: 500;
                }

                .box {
                    display: block;
                    width: 100%;
                    height: 10px;

                    &.green {
                        background: #02CB60 0% 0% no-repeat padding-box;
                    }

                    &.red {
                        background: #D1002A 0% 0% no-repeat padding-box;
                    }

                    &.blue {
                        background: #559EFE 0% 0% no-repeat padding-box;
                    }
                }

                p {
                    margin: 0;
                    font-weight: 500;


                    span.green {
                        color: #02CB60;
                    }

                    span.red {
                        color: #D1002A;
                    }

                    span.blue {
                        color: #559EFE;
                    }
                }
            }
        }
    }

    .recentUploads {
        flex: 1;
        gap: 2rem;
        width: 100%;
        display: flex;
        border-radius: 10px;
        padding: 1.5rem 1rem;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                margin: 0;
                font-size: 1rem;
                font-weight: 500;
            }
        }

        .UploadTable {
            white-space: nowrap;
            thead {
                tr {
                    th {
                        font-weight: 500;
                        font-size: 0.9rem;
                    }
                }
            }

            tbody {
                td {
                    .thumnbnail {
                        width: 10rem;
                        height: 5rem;
                        object-fit: cover;
                    }

                    vertical-align: middle;
                    font-size: 0.8rem;

                    &.loading {
                        padding: 1rem 0;

                        svg {
                            width: 5rem;
                        }
                    }
                }
            }
        }
    }
}