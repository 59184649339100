.rightSidebar {
    gap: 2rem;
    display: flex;
    flex-direction: column;

    .accountDetails {
        gap: 1rem;
        display: flex;
        border-radius: 10px;
        padding: 1.5rem 1rem;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                margin: 0;
                font-size: 1rem;
            }

            .action {
                display: flex;
                align-items: center;
            }
        }

        .accountImage {
            padding: 10px;
            width: 100%;
            height: 14rem;
            margin: 0 auto;
            border-radius: 10px;
            box-shadow: 0px 3px 6px #00000029;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .title {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
        }

        .details {
            display: flex;
            justify-content: space-evenly;

            @media only screen and (max-width: 1228px) {
                flex-direction: column;
                gap: 5px;
            }

            .column {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 5px;

                .title {
                    gap: 5px;
                    display: flex;
                    font-size: 0.9rem;
                    align-items: center;
                }

                p {
                    margin: 0;
                    font-size: 0.8rem;
                }
            }

            .divider {
                width: 1px;
                height: auto;
                background: #BABABA;

                @media only screen and (max-width: 1228px) {
                    width: auto;
                    height: 1px;
                    background: #BABABA;
                }
            }
        }
    }

    .transactionHistory {
        gap: 1rem;
        display: flex;
        border-radius: 10px;
        padding: 1.5rem 1rem;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            @media only screen and (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
            }

            h5 {
                margin: 0;
                font-size: 1rem;
            }

            .viewAll {
                color: #3F312D;
                font-weight: 500;
                font-size: 0.8rem;
                border-color: #EEEEEE;
                background-color: #EEEEEE;
            }
        }

        .list {
            margin: 0;
            padding: 0;

            li {
                padding: 1rem 0;
                list-style-type: none;
                border-bottom: solid 1px #FCF7F5;

                .listData {
                    gap: 10px;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: space-evenly;


                    @media only screen and (max-width: 991px) {
                        flex-direction: column;
                    }

                    img {
                        width: 60px;
                        height: 45px;
                        object-fit: cover;
                        border-radius: 8px;
                    }

                    p {
                        margin: 0;
                        font-weight: 500;
                        font-size: 0.8rem;
                        line-height: 1.2;

                        span {
                            display: block;
                            font-weight: 300;
                        }
                    }

                    .arrow {
                        padding: 6px;
                        cursor: pointer;
                        display: inline-block;
                        border: solid #E1DEDD;
                        transform: rotate(-45deg);
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(-45deg);

                        @media only screen and (max-width: 991px) {
                            position: absolute;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}