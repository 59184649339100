.signUpContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .signUpForm {
    flex: 1;
    gap: 1rem;
    height: 100%;
    margin: auto;
    padding: 2rem;
    display: flex;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 991px) {
      flex: .5;
      height: 80%;
      background-color: rgba(238, 238, 238, .80);
    }

    img {
      width: 10rem;
      margin: 0 auto;
    }

    label {
      font-weight: bold;
    }

    .loginHeader {
      display: flex;
      align-items: center;
      flex-direction: column;

      h6 {
        color: #575757;
        font-weight: bold;
        font-size: 1.5rem;
      }

      p {
        color: #575757;
      }
    }

    .formContainer {
      gap: 1rem;
      width: 100%;
      margin: 0 auto;
      display: flex;
      max-width: 22rem;
      flex-direction: column;
    }

    .forgotPassword {
      font-size: 14px;
      color: #D1002A;
      font-weight: 500;
      text-align: right;
      text-decoration: none;
    }

    .marginContainer {
      gap: 10px;
      width: 100%;
      display: flex;
      max-width: 22rem;
      margin: 1rem auto 0;
      flex-direction: column;

      button {
        width: 100%;
      }

      .submitButton {
        font-weight: 400;
        border-color: #D1002A;
        background-color: #D1002A;
      }

      .signup {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
        }

        .link {
          font-size: 12px;
          color: #D1002A;
          font-weight: 500;
          text-decoration: none;
        }

        .checkBox {
          display: flex;

          input {
            border: solid 1px #848484;

            &:checked[type=checkbox] {
              background-color: #848484 !important;
            }
          }

          p {
            font-size: 12px;

            span {
              font-size: 12px;
              color: #D1002A;
            }
          }
        }
      }

      .uploadId {
        margin: 0 0 1.5rem;

        label {
          margin: 0;
          padding: 0;
          width: 100%;
          display: flex;
          color: #fff;
          height: 37.6px;
          cursor: pointer;
          font-weight: 400;
          align-items: center;
          justify-content: center;
          border-radius: 0.375rem;
          background-color: #D1002A;
        }

        input {
          display: none;
        }
      }
    }
  }

  .imageContainer {
    flex: 1 1;
    height: 100%;

    @media only screen and (max-width: 991px) {
      position: absolute;
      z-index: -1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}