.analyticsContainer {
    height: 100%;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h4 {
        font-weight: 500;
        font-size: 1.2rem;
        margin: 0 0 1rem;
    }

    p {
        font-weight: 500;
        font-size: 14px;
    }

    .formContainer {
        gap: 1rem;
        width: 100%;
        display: flex;
        margin: 0 0 3rem;
        align-items: center;
        justify-content: space-between;

        .borderText {
            flex: 1;

            label {
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
            }

            input {
                border-radius: 8px;
                border: solid 1px #EEEEEE;
                background-color: #EEEEEE !important;
            }
        }
    }

    .actionButtons {
        gap: 1rem;
        width: 100%;
        display: flex;
        margin: 2rem 0 0;
        align-items: center;
        justify-content: flex-end;

        button {
            width: 7rem;
            font-size: 0.8rem;
            font-weight: 500;

            &.save {
                color: #02CB60;
                border-color: #EDF9F4;
                background-color: #EDF9F4;
            }

            &.cancel {
                color: #D1002A;
                border-color: #FFDFE6;
                background-color: #FFDFE6;
            }
        }
    }
}