.cast-image-upload {
    width: auto;

    .upload-cast-image-preview {
        width: 6rem;
        height: 6rem;
        margin: auto;
        display: block;
        cursor: pointer;
        border-radius: 3px;
        position: relative;
        border-radius: 1rem;
        background-color: #D0D0D0;

        .center-data {
            top: 0;
            left: 0;
            gap: 5px;
            right: 0;
            bottom: 0;
            margin: auto;
            color: #fff;
            display: flex;
            font-weight: 500;
            font-size: 0.7rem;
            position: absolute;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    .cast-image-preview {
        margin: 0;
        height: 6rem;
        width: 6rem;
        overflow: hidden;
        position: relative;
        border-radius: 1rem;
        padding-top: 56.25%;

        img {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            object-fit: cover;
            position: absolute;
        }
    }

    input {
        display: none;
    }
}