.successModal {
    .header {
        align-items: center;
        justify-content: center;
        background-color: #2A2A2A;

        .title {
            color: #fff;
            font-size: 1rem;
        }
    }

    .body {
        display: flex;
        padding: 2rem;
        align-items: center;
        flex-direction: column;

        p {
            font-weight: 400;
            text-align: center;
        }

        .close {
            width: 7rem;
            border-radius: 8px;
            border-color: #FF0000;
            background-color: #FF0000;
        }
    }
}