.header {
    flex: 1;
    gap: 2rem;
    width: 100%;
    max-width: 80%;
    padding: 2rem;
    margin: 0 auto 2.5rem;
    height: 333px;
    display: flex;
    position: relative;
    border-radius: 15px;
    flex-direction: column;
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('../../../assets/images/profile-bg.png');

    .innerHeader {
        gap: 1rem;
        width: 100%;
        height: 152px;
        display: flex;
        bottom: -12rem;
        position: relative;
        align-items: center;
        border-radius: 15px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .profile {
            gap: 1rem;
            height: 100%;
            display: flex;
            padding: 0 2rem;
            flex: 0 0 320px;
            align-items: center;

            p {
                margin: 0;
            }

            span {
                margin: 0;
            }

            .imageContainer {
                width: 8rem;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                .avatar {
                    width: 8rem;
                    height: 8rem;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }

            .name {
                p {
                    margin: 0 0 5px;
                    color: #1A1A1A;
                    font-weight: 600;
                    font-size: 1.2rem;
                    position: relative;

                    .check {
                        width: 14px;
                        right: -20px;
                        position: absolute;
                    }
                }

                span {
                    margin: 0;
                    font-size: 1rem;
                    color: #1A1A1A;
                    font-weight: 500;
                }
            }
        }

        .details {
            gap: 1rem;
            width: 100%;
            height: 7rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
                flex: 1;
                gap: 10px;
                margin: 0;
                width: 30%;
                display: flex;
                font-size: 12px;
                font-weight: 600;
                flex-direction: column;
                align-items: flex-start;

                span {
                    font-weight: 500;
                }

                svg {
                    width: 10px;
                    height: 10px;
                }

                b {
                    gap: 3px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .actions {
            flex: 0 0 10rem;
            gap: 10px;
            display: flex;
            padding-right: 2rem;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            button {
                width: 100%;
            }

            button.customize {
                font-weight: 600;
                color: #D1002A;
                font-size: 0.7rem;
                border-color: #FFDFE6;
                background-color: #FFDFE6;
            }

            button.password {
                font-weight: 600;
                color: #02CB60;
                font-size: 0.7rem;
                border-color: #EDF9F4;
                background-color: #EDF9F4;
            }
        }
    }
}